import React from "react"
import { Helmet } from "react-helmet"
import { graphql, Link } from "gatsby"
import { GatsbyImage } from "gatsby-plugin-image";
import SEO from "../components/seo"

import PageHeader from "../components/chrome/pageHeader"
import PageFooter from "../components/chrome/pageFooter"
import SimpleGallery from "../components/simpleGallery"
import Breadcrumbs from "../components/chrome/breadcrumbs"

import Main from '../components/main';

// eslint-disable-next-line no-unused-vars
import Styles from '../styles/styles.scss'

const LayoutReference = ({ data }) => {
  const reference = data.markdownRemark
  const Tags = reference.frontmatter.tags.map(tag => <li className="tag" key={tag.toLowerCase().replace(/ /g, '_')}><Link to={`/tags/${tag}`} className="tag__link">{tag}</Link></li>);
  const Technologies = reference.frontmatter.technologies && reference.frontmatter.technologies.map(technology => <li className="tag" key={technology.toLowerCase().replace(/ /g, '_')}>{technology}</li>);

  let subTitle = null;
  if (reference.frontmatter.subtitle !== '') {
    subTitle = <h2 className="reference__subtitle">{reference.frontmatter.subtitle}</h2>;
  }

  let smallImageFluid = null;
  if (reference.frontmatter.smallImage !== null) {
    smallImageFluid = reference.frontmatter.smallImage.childImageSharp.gatsbyImageData
  }
  let largeImageFluid = null;
  if (reference.frontmatter.largeImage !== null) {
    largeImageFluid = reference.frontmatter.largeImage.childImageSharp.gatsbyImageData
  }

  let smallImageAlt = " ";
  if (reference.frontmatter.smallImageAlt !== null) {
    smallImageAlt = reference.frontmatter.smallImageAlt;
  }
  let largeImageAlt = " ";
  if (reference.frontmatter.largeImageAlt !== null) {
    largeImageAlt = reference.frontmatter.largeImageAlt;
  }

  let realizationPeriod = reference.frontmatter.year !== null ? reference.frontmatter.year : 'n/a';

  let imageBlock = null;
  if (smallImageFluid !== null && largeImageFluid !== null) {
    imageBlock = <div className="reference__featuredImages row">
      <div className="col--6">
        <GatsbyImage image={smallImageFluid} alt={smallImageAlt || ' '} />
      </div>
      <div className="col--10">
        <GatsbyImage image={largeImageFluid} alt={largeImageAlt || ' '} />
      </div>
    </div>;
  }

  let simpleGalleryBlock = null;
  if (reference.frontmatter.simpleGalleryImages !== null) {
    simpleGalleryBlock = reference.frontmatter.simpleGalleryImages.map((gallery, index) =>
      <SimpleGallery
        key={index}
        gallery={gallery}
        useLightbox="true"
      />
    );
  }

  return (
    <div className="page page--reference">
      <PageHeader headerText={`Reference: ${reference.frontmatter.title} `} />
      <SEO title={reference.frontmatter.title} />
      <Main>

        <Breadcrumbs title={reference.frontmatter.title} path={reference.fields.slug} />

        <div className="row">
          <div className="col--16">
            <article className="reference">
              <header className="reference__header">
                <h1 className="reference__title">{reference.frontmatter.title}</h1>
                {subTitle}

                <dl className="reference__details">
                  <dt className="reference__term">Client</dt> <dd className="reference__definition">{reference.frontmatter.client}</dd>
                  <dt className="reference__term">Realization period</dt> <dd className="reference__definition">{realizationPeriod}</dd>
                  <dt className="reference__term">Technologies used</dt> <dd className="reference__definition"><ul className="tags">{Technologies ? Technologies : 'n/a'}</ul></dd>
                </dl>
              </header>


              <div className="reference__body" dangerouslySetInnerHTML={{ __html: reference.html }} />

              {imageBlock}

              {simpleGalleryBlock}

              <footer className="reference__footer">

                <dl className="reference__details reference__details--filing">
                  <dt className="reference__term">Filed under</dt> <dd className="reference__definition"><ul className="tags">{Tags}</ul></dd>
                  <dt className="reference__term">Topics</dt> <dd className="reference__definition">{reference.frontmatter.topics}</dd>
                </dl>

              </footer>

              <Helmet>
                <script type="application/ld+json">
                  {`{
                    "@context": "http://schema.org",
                    "@type": "CreativeWork",
                    "author": "${data.site.siteMetadata.author}",
                    "name": "Reference project: ${reference.frontmatter.title}",
                    "dateCreated": "${reference.frontmatter.date}",
                    "dateModified": "${reference.frontmatter.dateModified}",
                    "datePublished": "${reference.frontmatter.datePublished}"
                  }`}
                </script>
              </Helmet>

            </article>
          </div>
        </div>
      </Main>
      <PageFooter />
    </div>
  )
}

export const query = graphql`query ($slug: String!) {
  markdownRemark(fields: {slug: {eq: $slug}}) {
    fileAbsolutePath
    html
    frontmatter {
      title
      subtitle
      date
      dateModified
      datePublished
      tags
      topics
      technologies
      client
      year
      smallImage {
        publicURL
        childImageSharp {
          gatsbyImageData(width: 800, layout: CONSTRAINED)
        }
      }
      largeImage {
        publicURL
        childImageSharp {
          gatsbyImageData(width: 800, layout: CONSTRAINED)
        }
      }
      simpleGalleryImages {
        title
        galleryDescription
        images {
          thumb: file {
            childImageSharp {
              gatsbyImageData(
                width: 456
                height: 456
                transformOptions: {cropFocus: NORTH}
                layout: CONSTRAINED
              )
            }
          }
          full: file {
            childImageSharp {
              gatsbyImageData(
                layout: CONSTRAINED
              )
            }
          }
          alt
          description
          caption
        }
      }
    }
    fields {
      slug
    }
  }
  site {
    id
    siteMetadata {
      author
      description
      title
    }
  }
}
`

export default LayoutReference